<template>
  <v-container
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <v-btn
          color="primary"
          fab
          dark
          to="/pages/ead/videos/create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            Videos
            <v-spacer />
            <v-text-field
              v-model="filter.Term"
              label="Buscar"
              single-line
              hide-details
              @keyup.enter="load(true)"
            />
            <v-btn
              color="primary"
              @click="load(true)"
            >
              Buscar
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="videos"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="footer_props"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                >
                  <td>
                    {{ item.titulo }}
                  </td>
                  <td>
                    {{ item.url }}
                  </td>
                  <td>
                    {{ timestamp(item.createdAt) }}
                  </td>
                  <td>
                    <router-link :to="`/pages/ead/videos/${item.id}`">
                      <v-icon
                        class="mr-2"
                        color="info"
                      >
                        mdi-pencil
                      </v-icon>
                    </router-link>
                    <v-icon
                      color="error"
                      @click="destroy(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
            <v-progress-linear
              v-slot:progress
              color="blue"
              indeterminate
            />
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Você tem certeza?
        </v-card-title>

        <v-card-text>
          A video será deletada. Deseja prosseguir?
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn
            outlined
            class="ml-4"
            large
            color="error"
            dark
            @click="dialog = false"
          >
            Espere, vou verificar
          </v-btn>

          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            @click="confirmDelete()"
          >
            Sim, prossiga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'

  export default {
    data: () => ({
      itemselected: {},
      videos: [],
      headers: [
        { text: 'Nome', sortable: false },
        { text: 'URL Incorporação', sortable: false },
        { text: 'Criado em', sortable: false },
        { text: 'Ações', sortable: false },
      ],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      dialog: false,
      total: 0,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
        Term: '',
      },
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      timestamp (datestring) {
        return moment(datestring).format('DD/MM/YYYY HH:mm')
      },
      load (resetPage = false) {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = resetPage ? 1 : this.options.page
        const query = JSON.stringify(this.filter)
        this.$http.get('/ead/videos', { params: { query } })

          .then(resp => {
            this.videos = resp.data.rows
            this.total = resp.data.total
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      edit (item) {
        this.$router.push(`/ead/videos/${item.id}`)
      },
      destroy (item) {
        this.itemselected = item
        this.dialog = true
      },
      confirmDelete () {
        this.$http.delete(`/ead/videos/${this.itemselected.id}`)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.dialog = false
            this.load()
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
    },
  }
</script>

<style>
a {
  text-decoration: none;
}
</style>
